import axios from "axios";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import Form from "./Components/Form";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "./App.module.css";
import backgroundImage from "./include/web-final.jpg";

function App() {
  const [downloadLink, setDownloadLink] = useState(null);
  const [fileName, setFileName] = useState("downloaded.pdf");
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const downloadFileName = async () => {
      var res = await axios.post("/api/getBookName");
      setFileName(res.data);
    };
    downloadFileName();
  }, []);

  const downloadButton = async (email, institution, state) => {
    setWaiting(true);
    var res = await fetch("/api/downloadBook", {
      method: "POST",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        institution: institution,
        state: state,
      }),
    });
    console.log(res);
    var bb = await res.blob();
    if (res === "false") {
      console.log("error");
    } else {
      setDownloadLink(
        URL.createObjectURL(bb)
      );
      fileDownload(bb, fileName);
    }
    setWaiting(false);
  };

  return (
    <div>
      <img src={backgroundImage} className={classes.backgroundImage} />
      <Form sendFormHandler={downloadButton} downloadLink={downloadLink} waiting={waiting} />
      {/* {waiting && <CircularProgress />}
      <CircularProgress /> */}
    </div>
  );
}

export default App;
