import {
  Grid,
  FormControl,
  Input,
  InputLabel,
  Button,
  FormGroup,
} from "@mui/material";
import { useState, Fragment } from "react";

import CircularProgress from "@mui/material/CircularProgress";

import classes from "./Form.module.css";

const Form = (props) => {
  const [email, setEmail] = useState("");
  const [institution, setInstitution] = useState("");
  const [state, setState] = useState("");

  const onEmailChanged = (event) => {
    setEmail(event.target.value);
  };

  const onInstitutionChanged = (event) => {
    setInstitution(event.target.value);
  };

  const onStateChanged = (event) => {
    setState(event.target.value);
  };

  const sendResultsHandler = (event) => {
    event.preventDefault();
    props.sendFormHandler(email, institution, state);
  };

  return (
    <Grid
      className={classes.form}
      container
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      textAlign="center"
    >
      <Grid item xs={6} marginTop={10} className={classes.formBackground}>
      <h1>Practical SQL for Oracle Cloud</h1>
        <p>
          Note: The encryption password will be sent to your e-mail address
          after the download.
        </p>
        <p>
          In order to download this book, please fill out the following form.
        </p>
        <form onSubmit={sendResultsHandler}>
          <FormGroup>
            <FormControl margin="dense">
              <InputLabel htmlFor="email">Email address</InputLabel>
              <Input
                id="email"
                type="email"
                required={true}
                value={email}
                onChange={onEmailChanged}
              />
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl margin="dense">
              <InputLabel htmlFor="institution">Institution</InputLabel>
              <Input
                id="institution"
                type="text"
                required={false}
                value={institution}
                onChange={onInstitutionChanged}
              />
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl margin="dense">
              <InputLabel htmlFor="state">State</InputLabel>
              <Input
                id="state"
                type="text"
                required={true}
                value={state}
                onChange={onStateChanged}
              />
            </FormControl>
          </FormGroup>
          {props.waiting && (<Fragment><CircularProgress /><br></br></Fragment>)}
          <Button type="submit" variant="contained" style={{"fontSize": 25}}>
            Download
          </Button>
          {props.downloadLink !== null && (
            <p>
              If your download don't start automatically,{" "}
              <a href={props.downloadLink} download>
                click for download.
              </a>
            </p>
          )}
        </form>
        </Grid>
        <Grid item marginTop={10} marginLeft={5} className={classes.formBackground}>
        <h1>About book</h1>
        <h2>Authors: Michal Kvet, Karol Matiaško, Štefan Toth</h2>
        <h3>Content:</h3>
        <ul className={classes.list}>
          <li>Oracle Cloud Infrastructure (OCI)</li>
          <li>Basics of data retrieval</li>
          <li>Insert, Update, Delete statements and transactions</li>
          <li>Data modeling</li>
          <li>Create, Alter and Drop commands</li>
          <li>Data loading</li>
          <li>Managing privileges</li>
          <li>Advanced techniques of data retrieval</li>
          <li>Procedures, functions, packages</li>
          <li>Triggers</li>
          <li>Relational integrity</li>
          <li>Views</li>
          <li>Date and Time value management</li>
          <li>Data dictionary views</li>
          <li>Reports</li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default Form;
